import React from "react";

class ExcerptPanels extends React.Component {

    constructor(props){
        super(props);

        this.excerpts = props.excerpts;
    }

    //componentDidMount(){}
    //componentDidUpdate(prevProps, prevState){}

    render(){

        return (
            <div className="">
                { this.excerpts.map(( excerpt, index) => {

                    let excerptTitle;

                    if( excerpt.logo ) {
                        excerptTitle = () => {
                            return (`<img src="${excerpt.logo}" alt="${excerpt.title} logo" class="fill-current text-white w-3/4 xl:w-2/3 mx-auto"/>`);
                        }
                    } else {
                        excerptTitle = () => {
                            return (`<span className="text-3xl font-bold text-white">${excerpt.title}</span>`);
                        }
                    }

                    return (
                        <article className={"mb-10 md:mb-16"} key={index}>

                            <div className="relative rounded-lg md:flex items-stretch bg-gray-100 bg-opacity-80 shadow-xl"
                                 style={{minHeight: '19rem'}}>
                                <div
                                    className="relative w-full md:w-2/5 overflow-hidden rounded-t-lg md:rounded-t-none md:rounded-l-lg"
                                    style={{minHeight: '19rem'}}>
                                        <img className="absolute inset-0 w-full h-full object-cover object-center"
                                             src={excerpt.image}
                                             alt={excerpt.imageAlt}
                                        />
                                        <div className={`absolute inset-0 w-full h-full ${index % 2 ? 'bg-primary' : 'bg-secondary'} ${index % 2 ? 'opacity-75' : 'opacity-85'}`}></div>
                                    <div
                                        className="absolute inset-0 w-full h-full flex items-center justify-center">
                                        <h2 className="text-3xl font-bold text-white" dangerouslySetInnerHTML={{ __html: excerptTitle() }}>
                                        </h2>
                                    </div>
                                </div>
                                <div className="w-full md:w-3/5 flex items-center rounded-b-lg border-2 border-gray-200 md:border-0">
                                    <div className="p-6 md:pr-12 md:pl-16 md:py-12 lg:pr-24 lg:pb-16">
                                        <p className="text-gray-700 prose">{excerpt.summary}</p>
                                    </div>
                                    <span className="hidden lg:block absolute z-20 bottom-0 pb-3 right-0 pr-6 text-6xl font-bold text-gray-200">{`0${index + 1}`}</span>
                                    <svg className="hidden md:block absolute inset-y-0 h-full w-24 fill-current text-gray-100 -ml-12"
                                         viewBox="0 0 100 100" preserveAspectRatio="none">
                                        <path d="M50,0L100,0L50,100L0,100C24.377,72.416 46.218,42.943 50,0Z" style={{fill: '#F5F5F5'}}/>
                                    </svg>
                                </div>
                            </div>

                        </article>
                    )
                }) }
            </div>
        )
    }
}

export default ExcerptPanels
