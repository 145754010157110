import React from "react";
import { graphql } from "gatsby";

import PageTitle from "../components/page-title";
import Seo from "../components/seo";
import ExcerptPanels from "../components/excerpt-panels";
import { getSrc } from "gatsby-plugin-image";

export default function ServicesIndex({data}) {

    const services = data.services.edges.map(obj => {
        let rObj = {};
        rObj.path = obj.node.frontmatter.path;
        rObj.title = obj.node.frontmatter.title;
        rObj.image = getSrc(obj.node.frontmatter.image);
        rObj.imageAlt = obj.node.frontmatter.imageAlt;
        rObj.summary = obj.node.frontmatter.summary;
        return rObj
    });

    return(
        <>
            <Seo
                title="Services"
                description="An overview of Opworks AWS cloud services covering consultancy, migration, optimisation and management and every in between. We're ready to help at any stage of your journey to the cloud."
            />

            <PageTitle summary="We're ready to help at any stage of your journey to the cloud. We're able to draw on our vast experience with the AWS platform to deliver even the most challenging of projects."
                       title="Services"/>

            <div className={"mb-10 lg:mb-16 px-6 md:px-12 mx-auto prose prose-lg"}>
                <p>Think of Opworks as an extension of your own IT team for all things AWS cloud. We will design, build and maintain the foundations your organisation needs in the AWS cloud to compete with the best in your field. Our aim is to deliver a solid infrastructure that's resilient, secure and scalable, whilst also reducing costs and improving the metrics that matter to you.</p>
            </div>

            <div className="mb-10 lg:mb-32 max-w-screen-xl mx-auto px-6 md:px-12">
                <div className="lg:text-center">
                    <h3 className={"mt-2 text-3xl leading-8 font-bold tracking-tight text-indigo-600 sm:text-4xl sm:leading-10"}>How we can help</h3>
                    <p className="mt-4 mb-4 lg:mb-10 max-w-2xl prose prose-lg text-gray-700 lg:mx-auto">
                        Our AWS cloud services can be grouped under the following four main areas:
                    </p>
                </div>
                <ExcerptPanels excerpts={services}/>
            </div>

        </>
    )
}

export const query = graphql`{
  services: allMarkdownRemark(
    filter: {fileAbsolutePath: {glob: "**/content/services/*.md"}}
    sort: {fields: fileAbsolutePath, order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          path
          title
          image {
            childImageSharp {
              gatsbyImageData(width: 500, layout: FIXED)
            }
          }
          imageAlt
          summary
        }
      }
    }
  }
}
`;
